import React, { useState, useEffect } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import ButtonOrange from "../../common/ButtonOrange";
import { dark } from "../../../styles/Themes";
import { useNavigate } from "react-router-dom";
import yogaImage1 from "../../../Icons/Avtar/Yoga.jpg"; // Ensure you have an appropriate image
import yogaImage2 from "../../../Icons/Avtar/Yog2.png";
import yogaImage3 from "../../../Icons/Avtar/Yog3.png";
const images = [yogaImage1, yogaImage2, yogaImage3];

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const wave = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(0); }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  width: 90%;
  max-width: 800px;
  background: linear-gradient(135deg, #FFF2E6, #EAFDFF);
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-out, ${wave} 2s infinite;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PopupImageContainer = styled.div`
  align-content: center;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PopupImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  @media (max-width: 768px) {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

const PopupContent = styled.div`
  width: 50%;
  padding: 20px;
  align-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PopupTitle = styled.h2`
  color: ${(props) => props.theme.buttonColor};
  margin-bottom: 10px;
  font-size: ${(props) => props.theme.fontxl};
  font-weight: bold;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const PopupSubTitle = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
`;

const PopupText = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
`;

const CloseButton = styled.button`
     height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    top: 5px;
    right: 15px;
    background: #00000054;
    color: white;
  font-size: 2rem;
  cursor: pointer;
`;

const HighlightsTitle = styled.h4`
  font-size: 1.2rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const HighlightItem = styled.p`
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontlg};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  color: #666;
  margin-bottom: 5px;
`;

const Popup = ({ onClose }) => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);
  const handleClick = () => {
    navigate("/contactUs");
  };

  return (
    <PopupOverlay>
      <PopupContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <PopupImageContainer>
          <PopupImage src={images[currentImage]} alt="Yoga" />
        </PopupImageContainer>
        <PopupContent>
          <PopupTitle>YOGA FOR A BALANCED LIFE</PopupTitle>
          <PopupSubTitle>
            Connect with Your Inner Self in our Yoga Sessions
          </PopupSubTitle>
          {/* <PopupText>Date: 1 August 2023</PopupText>
          <PopupText>Time: 6:30 AM - 7:30 AM IST</PopupText> */}
          <HighlightsTitle>Program Highlights:</HighlightsTitle>
          <HighlightItem>
            Objective: Detoxify and balance your body through yoga.
          </HighlightItem>
          <HighlightItem>Duration: 15 sessions</HighlightItem>
          <HighlightItem>
            Content: Asanas, pranayama, meditation, and Yoga Nidra.
          </HighlightItem>
          <HighlightItem>
            Lifestyle: Learn to adopt a yogic lifestyle.
          </HighlightItem>
          <ThemeProvider theme={dark}>
            <ButtonOrange text="Book Your Seat" action={handleClick} />
          </ThemeProvider>
        </PopupContent>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default Popup;
