import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";

import Navigation from "./components/sections/HeaderFooter/Navigation";
import Home from "./components/sections/Home";
import About from "./components/sections/About/About";
import Roadmap from "./components/sections/Roadmap/Roadmap";
import SliderComponent from "./components/sections/Slider/SliderComponent";
import Yog from "./components/sections/Yog/Yog";
import Category from "./components/sections/Yog/Category";
import JoinNowComponent from "./components/common/JoinNowComponent";
import Footer from "./components/sections/HeaderFooter/Footer";
import ScrollToTop from "./components/sections/HeaderFooter/ScrollToTop";
import Rogyog from "./components/sections/RogYog/Rogyog";
import RogyogCat from "./components/sections/RogYog/RogyogCat";
import RogyogSubCat from "./components/sections/RogYog/RogyogSubCat";
import "rsuite/dist/rsuite.min.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, createBrowserRouter } from "react-router-dom";
import SubCategory from "./components/sections/Yog/SubCategory";
import SubSubCategory from "./components/sections/Yog/SubSubCategory";
import Testimonials from "./components/sections/Testimonials/Testimonials";
import ContactUsForm from "./components/sections/contactUs/ContactUsForm";
import ContactUSPrenatal from "./components/sections/contactUs/ContactUSPrenatal";
import Diet from "./components/sections/contactUs/Diet";


import Works from "./components/sections/Works/Works";
import Need from "./components/sections/Need/Need";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from 'react-ga4';
import Packages from "./components/sections/Packages/Packages"
import PrivacyPolicy from "./components/sections/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./components/sections/CookiePolicy/CookiePolicy"
import TermsAndConditions from "./components/sections/TermsAndConditions/Terms";
import Blog from "./components/sections/Blog/Blog"
import BlogDescription from "./components/sections/Blog/BlogDescription"
import PageTitle from './components/common/PageTitle';


const TRACKING_ID = "G-9QH6R7RSL6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  }, []);
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={light}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                <PageTitle title="Yoga Virtual Classes - Prenatal & Postnatal - Disease Specific Yoga" />
                  <Navigation />
                  {/* <SliderComponent /> */}
                  <Home />
                  <Works />
                  <Rogyog  isUpeerWave = {true} isLowerWave={true}/>
                  <Need />
                  <Yog  isUpeerWave = {true} isLowerWave={true}/>

                  {/* <Showcase /> */}
                  {/* <Faq /> */}

                  <About />
                  <Testimonials />
                  {/* <Roadmap /> */}
                  <JoinNowComponent />
                  <Footer />
                  {/* <ScrollToTop /> */}
                </>
              }
            />
             <Route
              path="/home"
              element={
                <>
                <PageTitle title="Online Yoga Virtual Classes" />
                  <Navigation />
                  {/* <SliderComponent /> */}
                  <Home />
                  <Works />
                  <Rogyog  isUpeerWave = {true} isLowerWave={true}/>
                  <Need />
                  <Yog  isUpeerWave = {true} isLowerWave={true}/>

                  {/* <Showcase /> */}
                  {/* <Faq /> */}

                  <About />
                  <Testimonials />
                  {/* <Roadmap /> */}
                  <JoinNowComponent />
                  <Footer />
                  {/* <ScrollToTop /> */}
                </>
              }
            />
            <Route
              path="/diet"
              element={
                <>
                  <PageTitle title="BENEFITS OF YOG"/>
                  <Navigation />
                  <Diet/>
                  <Works />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/yog"
              element={
                <>
                  <PageTitle title="BENEFITS OF YOG"/>
                  <Navigation />
                  <Yog isUpeerWave = {false} isLowerWave={true}/>
                  <Works />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/packages"
              element={
                <>
                  <PageTitle title="Yoga Packages | Book your free demo now" />
                  <Navigation />
                  <Packages />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/:category"
              element={
                <>
                  <PageTitle title="BENEFITS OF YOG " />
                  <Navigation />
                  <Category isUpeerWave = {false} isLowerWave={true}/>
                  <Works />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/:category/:subcategory"
              element={
                <>
                  <Navigation />
                  <SubCategory isUpeerWave = {false} isLowerWave={true}/>
                  <Need />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/:category/:subcategory/:id"
              element={
                <>
                  <Navigation />
                  <SubSubCategory />
                  <Works />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/:category/:subcategory/:id/:subid"
              element={
                <>
                  <Navigation />
                  <SubSubCategory />
                  <ScrollToTop />
                  <Works />
                  <Footer />
                </>
              }
            />
            <Route
              path="/rogyog"
              element={
                <>
                  <PageTitle title="Yoga For Diseases" />
                  <Navigation />
                  <Rogyog  isUpeerWave = {false} isLowerWave={true}/>
                  <Need />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <PageTitle title="About YogaShastra" />
                  <Navigation />
                  <SliderComponent />
                  <Works />
                  <About />
                  <Need />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/rogyog/:category"
              element={
                <>
                  <Navigation />
                  <RogyogCat isUpeerWave = {false} isLowerWave={true}/>
                  <Need />
                  <Footer />
                  <ScrollToTop />

                </>
              }
            />
            <Route
              path="/rogyog/:category/:subcategory"
              element={
                <>
                  <Navigation />
                  <RogyogSubCat />
                  <Works />
                  <Footer />
                  <ScrollToTop />
                </>
              }
            />
            <Route
              path="/privacypolicy"
              element={
                <>
                  <Navigation />
                  <PrivacyPolicy />
                  <Footer />
                  <ScrollToTop />

                </>
              }
            />
            <Route
              path="/cookiepolicy"
              element={
                <>
                  <Navigation />
                  <CookiePolicy />
                  <Footer />
                  <ScrollToTop />

                </>
              }
            />
            <Route
              path="/terms&condition"
              element={
                <>
                  <Navigation />
                  <TermsAndConditions />
                  <Footer />
                  <ScrollToTop />

                </>
              }
            />
            <Route
              path="/blog"
              element={
                <>
                  <PageTitle title="YogaShastra Blogs | Strat trial classes now" />
                  <Navigation />
                  <Blog />
                  <Footer />
                  <ScrollToTop />

                </>
              }
            />
            <Route path="/description/:sN"
            element = {
              <>
              
              <Navigation />
              <BlogDescription />
              <Footer />
              <ScrollToTop />
              
              </>
            }
            
            />
            {/* <Route path="/contactUs" element={<Form />} /> */}
            <Route path="/contactUs" element={
              <>
                <PageTitle title="Contact Us | Chat with Yogacharya" />
                <Navigation />
                <ContactUsForm />
                <Footer />
                <ScrollToTop />
              </>} />
              <Route path="*" element={<Blog />} />
              <Route path="/prenatalQuery" element={
              <>
                <PageTitle title="Prenatal & Postnatal | Chat with Yogacharya" />
                <Navigation />
                <ContactUSPrenatal />
                <Footer />
                <ScrollToTop />
              </>} />
              <Route path="*" element={<Blog />} />

          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
