import React, { useState, useEffect } from "react";
import Axios from "axios";
import styled from "styled-components";
import Spinner from "../../common/Spinner";

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  background-image: linear-gradient(
    ${(props) => props.theme.lightBlueShade},
    ${(props) => props.theme.yellowShade}
  );
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.buttonColor};
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: ${(props) => props.theme.fontsm};
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: ${(props) => props.theme.fontmd};
  background-color: ${(props) => props.theme.buttonColor};
  color: ${(props) => props.theme.body};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DietPlanContainer = styled.div`
  margin-top: 20px;
  background-color: ${(props) => props.theme.lightGrey};
  padding: 20px;
  border-radius: 10px;
`;

const DietPlanner = () => {
  const [formData, setFormData] = useState({
    age: "",
    gender: "",
    activityLevel: "",
    healthGoals: "",
    medicalConditions: "",
    calorieIntake: "",
    dietaryPreferences: "",
  });
  
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dietPlan, setDietPlan] = useState(null);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { age, gender, activityLevel, healthGoals, calorieIntake } = formData;
    if (age && gender && activityLevel && healthGoals && calorieIntake) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const calculateDietPlan = () => {
    const { age, gender, activityLevel, healthGoals, calorieIntake, dietaryPreferences } = formData;
    
    // Basic logic to calculate diet based on form inputs
    const basePlan = {
      breakfast: "Oats with fruits and nuts",
      lunch: "Grilled chicken with quinoa and veggies",
      dinner: "Salmon with sweet potatoes and spinach",
      snacks: "Greek yogurt and almonds",
    };

    // Adjust diet based on activity level, calorie intake, and preferences
    if (activityLevel === "Active") {
      basePlan.snacks = "Protein shake and a banana";
    } else if (activityLevel === "Sedentary") {
      basePlan.snacks = "Fruit salad";
    }

    if (calorieIntake < 1500) {
      basePlan.dinner = "Vegetable stir-fry with tofu";
    }

    if (dietaryPreferences === "Vegetarian") {
      basePlan.lunch = "Lentil salad with avocado";
      basePlan.dinner = "Vegetable curry with brown rice";
    }

    if (healthGoals === "Weight Loss") {
      basePlan.snacks = "Carrot sticks with hummus";
    } else if (healthGoals === "Muscle Gain") {
      basePlan.breakfast = "Scrambled eggs with whole-grain toast";
      basePlan.snacks = "Peanut butter on whole-grain crackers";
    }

    return basePlan;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      setLoading(true);
      try {
        // Simulate an API request
        await Axios.post("/api/diet-plan", formData); // replace with actual API
        const plan = calculateDietPlan(); // Generate the diet plan
        setDietPlan(plan);
        setLoading(false);
      } catch (error) {
        console.error("Error generating diet plan:", error);
        setLoading(false);
      }
    }
  };

  return (
    <Section>
      {loading && <Spinner />}
      <FormContainer>
        <Title>Plan Your Diet</Title>
        <form onSubmit={handleSubmit}>
          <InputField
            type="text"
            name="age"
            placeholder="Your Age"
            value={formData.age}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="gender"
            placeholder="Your Gender"
            value={formData.gender}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="activityLevel"
            placeholder="Activity Level (e.g., Sedentary, Active)"
            value={formData.activityLevel}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="healthGoals"
            placeholder="Health Goals (e.g., Weight Loss, Muscle Gain)"
            value={formData.healthGoals}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="medicalConditions"
            placeholder="Any Medical Conditions"
            value={formData.medicalConditions}
            onChange={handleChange}
          />
          <InputField
            type="number"
            name="calorieIntake"
            placeholder="Calorie Intake"
            value={formData.calorieIntake}
            onChange={handleChange}
            required
          />
          <InputField
            type="text"
            name="dietaryPreferences"
            placeholder="Dietary Preferences (e.g., Vegetarian)"
            value={formData.dietaryPreferences}
            onChange={handleChange}
          />

          <SubmitButton type="submit" disabled={!isFormValid}>
            Submit
          </SubmitButton>
        </form>

        {/* Display Diet Plan */}
        {dietPlan && (
          <DietPlanContainer>
            <h2>Your Diet Plan</h2>
            <p><strong>Breakfast:</strong> {dietPlan.breakfast}</p>
            <p><strong>Lunch:</strong> {dietPlan.lunch}</p>
            <p><strong>Dinner:</strong> {dietPlan.dinner}</p>
            <p><strong>Snacks:</strong> {dietPlan.snacks}</p>
          </DietPlanContainer>
        )}
      </FormContainer>
    </Section>
  );
};

export default DietPlanner;
